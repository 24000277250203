var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"tree-node",class:{
        'no-style': !_vm.level,
        'child-node': _vm.level,
        'selectable': _vm.isSelectable,
        'selected': _vm.isSelected,
        'foldable': !_vm.isSelectable,
    }},[(_vm.isSelectable)?_c('div',{staticClass:"label",class:{ disabled: _vm.node.disabled },on:{"click":function($event){return _vm.select(_vm.node)}}},[_vm._v(" "+_vm._s(_vm.node.label)+" ")]):_c('div',{staticClass:"label foldable",class:{ 'multiselect--active': _vm.isFolded },on:{"click":function($event){_vm.isFolded = !_vm.isFolded}}},[_c('div',{staticClass:"multiselect__select"}),_vm._v(" "+_vm._s(_vm.node.label)+" ")]),(_vm.node.children && _vm.node.children.length)?_c('ul',{class:{ folded: _vm.isFolded }},_vm._l((_vm.node.children),function(child,index){return _c('TreeNode',{key:`tree_${index}_${_vm.level}`,attrs:{"node":child,"level":_vm.level + 1,"selected-list":_vm.selectedList},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.select($event)}}})}),1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <transition name="fade">
        <div v-if="show" class="overlay" @click.self="hide()">
            <transition name="fade">
                <div class="card modal modal-lg modal-edit-role">
                    <div class="flex-grow-0 bg-white border-0 card-header">
                        <h2 class="w-full px-4 py-2 -mx-4 font-normal">{{ title }}</h2>

                        <button class="ml-auto text-gray-500 close-modal-btn" @click="hide">
                            <svg-importer icon-name="icons/close" key="hide-close" />
                        </button>
                    </div>

                    <div slot="body" class="flex flex-wrap p-6 border-t border-gray-500">
                        <div 
                            v-for="(input, index) in inputs"
                            :key="'input' + index"
                            :class="input.width"
                        >
                            <modal-field 
                                v-if="input.type == 'text'"
                                :label="input.title" 
                                class="flex-wrap w-full px-4 mt-4"
                                :required="input.required"
                                
                            >   
                                <input 
                                    type="text"
                                    class="w-full" 
                                    v-model="input.value"
                                    :class="{'border-red-500' : validationErrors[input.key]}"
                                    :placeholder="input.value"
                                />

                                <transition name="fade">
                                    <span
                                        v-if="validationErrors[input.key]"
                                        class="w-full text-red-500 help-block"
                                    >
                                        <span>{{validationErrors[input.key][0]}}</span>
                                    </span>
                                </transition>
                            </modal-field>

                            <modal-field 
                                v-if="input.type == 'select'"
                                :label="input.title" 
                                class="flex-wrap w-full px-4 mt-4"
                                :required="input.required"    
                            >
                                <suggestions-tree v-model="input.value" :options="permissionOptions" title="Permissions" />
                            </modal-field>
                        </div>
                    </div>
                
                    <div class="flex p-4 mt-auto">
                        <div slot="footer" class="w-full py-4 pr-6 text-right">
                            <button class="btn-primary" @click="saveRole">
                                {{ submitButtonText }}
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>
<script>

import axios from 'axios';
import SuggestionsTree from '@/components/TreeSuggestions/Tree';

export default {
    name: 'AddRoleModal',
    components: {
        SuggestionsTree,
    },
    props: {
        show: Boolean,
        size: String,
        role: {
            type: Object,
            default: null,
        }
    },

    data: () => ({
        visible: false,
        validationErrors: {},
        loading: false,
        inputs:  [
            {
                title: 'Name',
                key: 'name',
                value: null,
                type: 'text',
                width: 'w-full',
            },
            {
                title: 'Permissions',
                key: 'permissions',
                value: [],
                type: 'select',
                width: 'w-full',
            },
        ],
        permissionOptions: [],
    }),

    computed: {
        isEdit() {
            return Boolean(this.role);
        },
        endpoint() {
            return this.isEdit ? `${this.$apiUrl.roles.base}/${this.role.id}` : this.$apiUrl.roles.base;
        },
        roleName() {
            return this.inputs.find(input => input.key === 'name')?.value || '';
        },
        apiMessage() {
            return {
                success: this.isEdit ? `Role ${this.roleName} updated!` : 'Role created!',
                error: this.isEdit ? `Failed to update ${this.roleName} role` : 'Failed to create role',
            } 
        },
        submitButtonText() {
            return this.isEdit ? 'Save role' : 'Add role';
        },
        title() {
            return this.isEdit ? 'Edit role' : 'Add new role';
        },
    },

    async created () {
        this.toggleBodyClass('remove', 'modal-open')
        this.visible = false;

        const { data: { data } } = await axios.get(this.$apiUrl.permissions.tree);
        this.permissionOptions = data.children;
    },

    watch: {
        loading(newVal) {
            this.$emit('loading', newVal);
        },
        role(value) {
            if (!value) {
                this.clearRoleForm();

                return;
            }

            this.inputs.forEach(input => {
                if (!(input.key in value)) {
                    return;
                }

                input.value = value[input.key];
            });
        },
    },

    methods: {
        prepareRequestData() {
            const result = {};
            this.inputs.forEach(input => {
                if (input.type === 'select') {
                    result[input.key] = input.value.map(val => val.id);
                    
                    return;
                }

                result[input.key] = input.value;
            });

            return result;
        },
        async saveRole () {
            this.loading = true;
            this.validationErrors = [];


            try {
                const data = this.prepareRequestData();

                await axios.post(this.endpoint, data);

                this.$snotify.success(this.apiMessage.success);

                this.hide();
            } catch (error) {
                this.loading = false;

                if(error?.response?.status == 422) {
                    this.validationErrors = error.response.data.errors;
                }

                this.$snotify.error(this.apiMessage.error);
            }

            this.$emit('reload');
        },
        createRole() {
            this.loading = true;

            this.hide();
        },
        clearRoleForm() {
            this.inputs.forEach(element => {
                if (element.key === 'permissions') {
                    element.value = [];

                    return;
                }

                element.value = null;
            });
        },
        hide() {
            this.clearRoleForm();
            this.loading = false;
            this.$emit('hide');
        },
        escape() {
            document.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.hide();
                }
            });
        }
    },
    mounted() {
        this.escape();
    }
}
</script>
<style>
    .modal-edit-role {
        height: 600px;
        max-height: none;
    }
</style>
<template>
    <div
        ref="dropdownSearch"
        class="tree mr-search-drop-block__wrapper mr-search-drop-block__wrapper--multiple cp-input-radius-50"
    >
        <label
            class="mr-search-drop__wrapper"
            :class="{ 'multiselect--active': isShowHints }"
            @click="toggleShowHints"
        >
            <div class="multiselect__select"></div>
            <div class="suggestion__tags-wrapper">
                <div class="suggestion__tags">
                    <span v-for="(item, index) in value" :key="`${item.id}_${index}`" class="suggestion__tag">
                        {{ item.name }}
                    </span>
                </div>
            </div>
        </label>

        <ul v-if="isShowHints" class="tree-list mr-select-default-list__wrapper cp-input-radius-25">
            <TreeNode
                v-for="(node, index) in options"
                :key="`tree_${index}_${node.id}_root`"
                :node="node"
                :selected-list="value"
                @click="select"
            />
        </ul>
    </div>
</template>
<script>

import TreeNode from './TreeNode.vue';

export default {
    components: {
        TreeNode,
    },

    props: {
        value: {
            type: Array,
            default: () =>  [],
        },
        options: Array,
        isSelect: Boolean,
        title: {
            type: String,
            default: '',
        }
    },

    data() {
        return {
            isShowHints: false,
        }
    },

    model: {
        prop: 'value',
        event: 'update:value',
    },

    computed: {
        modelValue: {
            get() {
                return this.value;
            },
            set(data) {
                this.$emit('update:value', data);
            },
        },
        listKey() {
            return this.isSelect ? 'list' : 'hintsList';
        },
        joinedSelectedItems() {
            return this.value.map(permission => permission.label);
        },
    },

    methods: {
        toggleShowHints() {
            this.isShowHints = !this.isShowHints;
        },
        showHints(e) {
            this.$emit('input', e.target?.value ?? '');
    
            this.isShowHints = true;
        },
    
        close() {
            this.isShowHints = false;
        },
    
        select(data) {
            const matchedIndex = this.value?.findIndex(item => item.id === data.id);

            if (typeof matchedIndex === 'number' && matchedIndex !== -1) {
                this.modelValue.splice(matchedIndex, 1);

                return;
            }

            this.modelValue.push(data);
        },
    
        updateSearch(e) {
            const value = e.target.value;
            this.isShowHints = true;
            this.$emit('input', value);
        },
    
        documentClick(e) {
            const el = this.$refs.dropdownSearch;
            const target = e.target;
    
            if (this.isShowHints === true) {
                if (el !== target && !el.contains(target)) {
                    this.close();
                }
            }
        },
    },

    created() {
        document.body.addEventListener('click', this.documentClick);
    },
    updated() {
        document.body.addEventListener('click', this.documentClick);
    },
    destroyed() {
        document.body.removeEventListener('click', this.documentClick);
    }
}
</script>

<style lang="scss">
.suggestion__tag {
    position: relative;
    background: var(--brand-color);
    color: #fff;
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
    border-radius: 0.25rem;
    position: relative;
    display: inline-block;
    padding: 4px 8px 4px 8px;
}

.suggestion__tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0.3rem 0.3rem 0 0.3rem;
    max-height: 58px;
    overflow: hidden;
}

.suggestion__tags-wrapper {
    padding-right: 40px;
    border-width: 1px;
    border-color: #E6E6E6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
    border-radius: 0.25rem;
    min-height: 34px;
}

.tree-list {
    background: #fff;
    width: 100%;
    list-style: none;
    padding-left: 18px;
    margin: 0;
    max-height: 300px;
    overflow: auto;
    position: absolute;

    border-width: 1px;
    border-color: #E6E6E6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
    border-radius: 0.25rem;

    li {
        position: relative;

        &.no-style {
            &::before {
                content: none;
            }
        }

        &.child-node {
            padding-right: 0;
        }

        &.selected {
            .label {
                background: #f3f3f3;
            }
        }

        &.selectable {
            cursor: pointer;

            .label {
                &:hover {
                    background: var(--brand-color);
                    color: #fff;
                }
            }
        }


        &::before {
            content: '';
            display: block;
            width: 5px;
            height: 10px;

            position: absolute;
            left: 0;
            top: -4px;
            transform: translateY(50%);
        }
    }

    ul {
        list-style: none;
        padding-left: 20px;
    }

    .mr-select-default-item-check {
        top: 1px;
        transform: none;
    }
}
</style>

<template>
    <li
        class="tree-node"
        :class="{
            'no-style': !level,
            'child-node': level,
            'selectable': isSelectable,
            'selected': isSelected,
            'foldable': !isSelectable,
        }"
    >
        <div v-if="isSelectable" class="label" :class="{ disabled: node.disabled }" @click="select(node)">
            {{ node.label }}
        </div>

        <div v-else class="label foldable" :class="{ 'multiselect--active': isFolded }" @click="isFolded = !isFolded">
            <div class="multiselect__select"></div>

            {{ node.label }}
        </div>

        <ul v-if="node.children && node.children.length" :class="{ folded: isFolded }">
            <TreeNode
                v-for="(child, index) in node.children"
                :key="`tree_${index}_${level}`"
                :node="child"
                :level="level + 1"
                :selected-list="selectedList"
                @click.self="select($event)"
            />
        </ul>
    </li>
</template>
<script>
export default {
    name: 'TreeNode',

    props: {
        node: Object,
        level: {
            type: Number,
            default: 0,
        },
        selectedList: Array,
    },

    data() {
        return {
            isFolded: false,
        }
    },

    computed: {
        isSelected() {
            return this.selectedList?.find(item => item.id === this.node.id);
        },
        isSelectable() {
            return Boolean(this.node.id);
        },
    },

    methods: {
        select(item) {
            if (item.disabled) {
                return;
            }
    
            this.$emit('click', item);
        }
    },

}
</script>
<style lang="scss">
.tree-node {
    ul.folded {
        display: none;
    }
    .label {
        cursor: pointer;
        padding: 4px 30px 4px 12px;
        margin: 5px;
        
        &.disabled {
            opacity: 0.5;
            &:hover {
                background: transparent;
                cursor: default;
            }
        }
        &.foldable {
            &:hover {
                background: #959595;
            }
        }
        .multiselect__select {
            left: -22px;
            top: -5px;
        }
    }
}
</style>
